import { GitHub, LinkedIn } from "@mui/icons-material";
import { Modal } from "@mui/material";
import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Container = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000000a7;
    display: flex;
    align-items: top;
    justify-content: center;
    overflow-y: scroll;
    transition: all 0.5s ease;
`;

const Wrapper = styled.div`
    max-width: 800px;
    width: 100%;
    border-radius: 16px;
    margin: 50px 12px;
    height: min-content;
    background-color: ${({ theme }) => theme.card};
    color: ${({ theme }) => theme.text_primary};
    padding: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
`;

const Image = styled.img`
    width: 100%;
    object-fit: cover;
    border-radius: 12px;
    margin-top: 30px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
`;

const Title = styled.div`
    font-size: 28px;
    font-weight: 600;
    color: white;
    margin: 8px 6px 0px 6px;
`;

const Date = styled.div`
    font-size: 16px;
    margin: 2px 6px;
    font-weight: 400;
    color: white;
`;

const Desc = styled.div`
    font-size: 16px;
    font-weight: 400;
    color: white;
    margin: 8px 6px;
`;

const Tags = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 8px 0px;
`;

const Tag = styled.div`
    font-size: 14px;
    font-weight: 400;
    color: white;
    margin: 4px;
    padding: 4px 8px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.primary + 20};
`;

const Members = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    flex-wrap: wrap;
    margin: 12px 6px;
`;

const Member = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

const MemberImage = styled.img`
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 4px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
`;

const MemberName = styled.div`
    font-size: 16px;
    font-weight: 500;
    width: 200px;
    color: white;
`;

const ButtonGroup = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 12px 0px;
    gap: 12px;
`;

const Button = styled.a`
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: white;
    padding: 12px 16px;
    border-radius: 20px;
    border: 2px solid hsl(239, 53%, 59%);
    cursor: pointer;
    text-decoration: none;
    transition: all 0.5s ease;

    &:hover {
        background-color: ${({ theme }) => theme.primary + 99};
    }
`;

// Slider settings
const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const ProjectModal = ({ openModal, setOpenModal }) => {
    const project = openModal?.project;
    if (!project) return null;

    return (
        <Modal open={true} onClose={() => setOpenModal({ state: false, project: null })}>
            <Container>
                <Wrapper>
                    <button
                        style={{
                            position: "absolute",
                            top: "15px",
                            right: "15px",
                            cursor: "pointer",
                            fontSize: "28px",  // Readable text size
                            padding: "8px 16px",  // Comfortable click area
                            border: "none",
                            background: "transparent",
                            color: "white",
                            fontWeight: "bold",
                            transition: "all 0.3s ease",
                        }}
                        onMouseEnter={(e) => (e.target.style.color = "red")}
                        onMouseLeave={(e) => (e.target.style.color = "white")}
                        onClick={() => setOpenModal({ state: false, project: null })}
                    >
                        ✖
                    </button>
                    <br />
                    <br />

                    {/* Show multiple images only if there is more than one */}
                    {project.images?.length > 1 ? (
                        <Slider {...settings}>
                            {project.images.map((img, index) => (
                                <Image key={index} src={img} alt={`${project.title} Screenshot ${index + 1}`} />
                            ))}
                        </Slider>
                    ) : (
                        <Image src={project.image} alt={project.title} />
                    )}

                    <Title>{project?.title}</Title>
                    <Date>{project?.date}</Date>

                    <Tags>
                        {project?.tags.map((tag, index) => (
                            <Tag key={index}>{tag}</Tag>
                        ))}
                    </Tags>

                    <Desc>{project?.description}</Desc>

                    {project.member && (
                        <>
                            <Members>
                                {project?.member.map((member, index) => (
                                    <Member key={index}>
                                        <MemberImage src={member.img} />
                                        <MemberName>{member.name}</MemberName>
                                        <a href={member.github} target="new" style={{ textDecoration: "none", color: "inherit" }}>
                                            <GitHub />
                                        </a>
                                        <a href={member.linkedin} target="new" style={{ textDecoration: "none", color: "inherit" }}>
                                            <LinkedIn />
                                        </a>
                                    </Member>
                                ))}
                            </Members>
                        </>
                    )}

                    <ButtonGroup>
                        <Button href={project?.github} target="new">View Code</Button>
                        {/* <Button href={project?.webapp} target="new">View Live App</Button> */}
                    </ButtonGroup>
                </Wrapper>
            </Container>
        </Modal>
    );
};

export default ProjectModal;
