import React, { useEffect, useState } from "react";

const CustomCursor = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [clicked, setClicked] = useState(false);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    const moveCursor = (e) => {
      setPosition({ x: e.clientX, y: e.clientY });
    };

    const clickEffect = () => {
      setClicked(true);
      setTimeout(() => setClicked(false), 200);
    };

    const hoverEffect = (e) => {
      const isHovering = e.target.tagName === "A" || e.target.tagName === "BUTTON";
      setHovered(isHovering);
    };

    window.addEventListener("mousemove", moveCursor);
    window.addEventListener("mousedown", clickEffect);
    window.addEventListener("mouseover", hoverEffect);

    return () => {
      window.removeEventListener("mousemove", moveCursor);
      window.removeEventListener("mousedown", clickEffect);
      window.removeEventListener("mouseover", hoverEffect);
    };
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        top: position.y,
        left: position.x,
        width: clicked ? "20px" : hovered ? "40px" : "30px",
        height: clicked ? "20px" : hovered ? "40px" : "30px",
        backgroundColor: "rgba(255, 0, 102, 0.6)", // Neon pink color
        borderRadius: "50%",
        pointerEvents: "none",
        transform: "translate(-50%, -50%)",
        transition: "width 0.2s ease, height 0.2s ease, background-color 0.3s ease",
        zIndex: 9999,
        boxShadow: hovered ? "0 0 15px rgba(255, 0, 102, 0.8)" : "0 0 5px rgba(255, 0, 102, 0.5)",
      }}
    />
  );
};

export default CustomCursor;
