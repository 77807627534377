export const Bio = {
  name: "Vraj Raval",
  roles: [
    "Full Stack Developer",
    "UI/UX Designer",
    "Android Developer",
    "Programmer",
  ],
  description:
    "I am a dynamic professional with a passion for learning and excellence. Eager to embrace new challenges, I deliver high-quality results with a positive attitude and growth mindset. Ready to make impactful contributions and achieve great things.",
  github: "https://github.com/Vraj-Raval",
  resume:
    "https://drive.google.com/file/d/1rn3o61Pj-RQT5Sq0znuDF7gRsxZQmlSv/view?usp=sharing",
  linkedin: "https://www.linkedin.com/in/vraj-raval-54790b231/",
  twitter: "https://x.com/VrajRaval_",
  insta: "https://www.instagram.com/vrajrvl/",

};

export const skills = [
  {
    title: "Frontend",
    skills: [
      {
        name: "React Js",
        image:
          "https://www.svgrepo.com/show/452092/react.svg",
      },
      {
        name: "HTML",
        image: "https://www.svgrepo.com/show/452228/html-5.svg",
      },
      {
        name: "CSS",
        image:
          "https://www.svgrepo.com/show/452185/css-3.svg",
      },
      {
        name: "JavaScript",
        image:
          "https://img.icons8.com/?size=100&id=108784&format=png&color=000000",
      },
      {
        name: "Bootstrap",
        image:
          "https://getbootstrap.com/docs/5.3/assets/brand/bootstrap-logo-shadow.png",
      },
      {
        name: "Tailwind CSS",
        image:
          "https://img.icons8.com/?size=100&id=4PiNHtUJVbLs&format=png&color=000000",
      },
      {
        name: "Flutter",
        image:
          "https://cdn-images-1.medium.com/max/1200/1*5-aoK8IBmXve5whBQM90GA.png",
      },
    ],
  },
  {
    title: "Backend",
    skills: [
      {
        name: "Node Js",
        image: "https://www.svgrepo.com/show/378837/node.svg",
      },
      {
        name: "Express Js",
        image:
          "https://www.svgrepo.com/show/330398/express.svg",
      },
      {
        name: "Microsoft Sql Server",
        image:
          "https://www.svgrepo.com/show/303229/microsoft-sql-server-logo.svg",
      },
      {
        name: "MongoDB",
        image:
          "https://www.svgrepo.com/show/331488/mongodb.svg",
      },
      {
        name: "Firebase",
        image: "https://www.vectorlogo.zone/logos/firebase/firebase-icon.svg",
      },
    ],
  },
  {
    title: "Android",
    skills: [
      {
        name: "Java",
        image:
          "https://www.svgrepo.com/show/452234/java.svg",
      },
      {
        name: "Dart",
        image:
          "https://www.svgrepo.com/show/353631/dart.svg",
      },
      {
        name: "Android Studio",
        image:
          "https://img.icons8.com/?size=100&id=EgOU93v1DHjU&format=png&color=000000",
      },
    ],
  },
  {
    title: "Others",
    skills: [
      {
        name: "Git",
        image:
          "https://www.svgrepo.com/show/452210/git.svg",
      },
      {
        name: "GitHub",
        image:
          "https://www.svgrepo.com/show/512317/github-142.svg",
      },
      {
        name: "VS Code",
        image:
          "https://www.svgrepo.com/show/452129/vs-code.svg",
      },
      {
        name: "Postman",
        image:
          "https://www.svgrepo.com/show/354202/postman-icon.svg",
      },
      {
        name: "Figma",
        image:
          "https://www.svgrepo.com/show/452202/figma.svg",
      },
      {
        name: "Pandas",
        image:
          "https://www.svgrepo.com/show/473742/pandas.svg",
      },
    ],
  },
];

export const experiences = [
  {
    id: 0,
    img: "https://res.cloudinary.com/dkfuksxev/image/upload/v1742113123/1661493099915_bt2uce.jpg",
    role: "Web Developer Intern",
    company: "Internpe Pvt. Ltd.",
    date: "Sep 2023 - Dec 2023",
    desc: "Created Web-based projects (Ecommerce Website, TO-DO list), actively contributing to company operations.",
    skills: [
      "HTML",
      "CSS",
      "JavaScript",
      "JavaScript"
    ],
    doc: "https://res.cloudinary.com/dkfuksxev/image/upload/v1742061386/VRAJ_RAMESHBHAI_RAVAL_Completion_Certificate_pb3hjg.png",
  },
  {
    id: 0,
    img: "https://res.cloudinary.com/dkfuksxev/image/upload/v1742113125/Screenshot_2024-05-26_122842_eo70fq.png",
    role: "Teaching Assistantship in Python",
    company: "Darshan University Rajkot",
    date: "2023",
    desc: "As a Python Teaching Assistant, I assist with coursework, provide lab guidance, offer one-on-one tutoring, and help students understand and master Python programming.",


  }
];
export const certificates = [
  
  {
    "id": 2,
    "img": "https://logowik.com/content/uploads/images/udemy-new-20212512.jpg",
    "role": "The Complete 2024 Web Development Bootcamp",
    "company": "Udemy",
    "date": "May 29, 2024",
    "desc": "Completed 'The Complete 2024 Web Development Bootcamp' by Dr. Angela Yu on Udemy, covering full-stack web development with HTML, CSS, JavaScript, Node.js, Express, MongoDB, and more.",
    "skills": [
        "HTML",
        "CSS",
        "JavaScript",
        "Node.js",
        "Express",
        "MongoDB"
    ],
    "doc": "https://res.cloudinary.com/dkfuksxev/image/upload/v1742061423/UC-435c51a4-5ec3-4b12-a349-4184aec776a8_syv757.jpg"
}
,
  {
    id: 1,
    img: "https://upload.wikimedia.org/wikipedia/commons/b/b1/Tata_Consultancy_Services_Logo.svg",
    role: "TCS iON NQT - IT",
    company: "Tata Consultancy Services (TCS)",
    date: "Dec 2024",
    desc: "Achieved a total score of 70.68% in TCS iON National Qualifier Test (NQT) for IT, demonstrating strong aptitude, reasoning, and programming skills.",
    skills: [
      "Numerical Ability",
      "Verbal Ability",
      "Reasoning Ability",
      "Java Programming"
    ],
    doc: "https://res.cloudinary.com/dkfuksxev/image/upload/v1742061366/22071555260_page-0001_sjiqxn.jpg",
  }
];


export const education = [
  {
    id: 0,
    img: "https://res.cloudinary.com/dkfuksxev/image/upload/v1742113125/Screenshot_2024-05-26_122842_eo70fq.png",
    school: "Darshan University, Rajkot",
    date: "2021 - 2025",
    grade: "7.66 CGPA",
    desc: "I am currently in the 8th semester of my Bachelor's degree in Computer Science and Engineering at Darshan University, Rajkot, with a CGPA of 7.66. My coursework includes foundational and advanced topics such as Data Structures, Algorithms, Object-Oriented Programming, Database Management Systems, Operating Systems, and Computer Networks, among others.",
    degree: "Computer Science and Engineering",
  },
  {
    id: 1,
    img: "https://res.cloudinary.com/dkfuksxev/image/upload/v1742113122/300434269_558443459407649_3341149347748459891_n_ofcs0l.png",
    school: "Modi School, Rajkot",
    date: "2020 - 2021",
    grade: "77.69%",
    desc: "I completed my class 12 high school education at Methodist School, Dankuni, where I studied Science with Computer subject.",
    degree: "XII (HSC-GSEB)",
  },
  {
    id: 2,
    img: "https://res.cloudinary.com/dkfuksxev/image/upload/v1742113122/1556273027_logo_doze1u.png",
    school: "K.G. Dholakiya School, Rajkot",
    date: "2018 - 2019",
    grade: "77.50%",
    desc: "I completed my class 10 education at K.G. Dholakiya School, Rajkot.",
    degree: "X (SSC-GSEB)",
  },
];

export const projects = [
  {
    id: 5,
    title: "EduSphere - LMS",
    date: "Dec 2024 - Feb 2025",
    image: "https://res.cloudinary.com/dkfuksxev/image/upload/v1742060311/screencapture-localhost-5173-2025-03-15-21_52_35_rxbflt.png",
    description:
      "A web-based platform enabling instructors to create and manage courses while students enroll, access materials, and track progress. Built with the MERN stack, it features secure authentication, progress tracking, and payments, ensuring a seamless and engaging learning experience.",
    images: [
      "https://res.cloudinary.com/dkfuksxev/image/upload/v1742060311/screencapture-localhost-5173-2025-03-15-21_52_35_rxbflt.png",
      "https://res.cloudinary.com/dkfuksxev/image/upload/v1742060310/screencapture-localhost-5173-course-search-2025-03-15-21_52_54_dtc2tv.png",
      "https://res.cloudinary.com/dkfuksxev/image/upload/v1742060308/screencapture-localhost-5173-course-detail-67b0982a72032066153e89db-2025-03-15-21_53_37_hnytmt.png",
      "https://res.cloudinary.com/dkfuksxev/image/upload/v1742060325/screencapture-localhost-5173-course-progress-67b0a5e293e17f5ffba8d51e-2025-03-15-21_54_05_geenxv.png",
      "https://res.cloudinary.com/dkfuksxev/image/upload/v1742060311/screencapture-checkout-stripe-c-pay-cs-test-a1KeWRi9jWfOsQAfJOnGtOFgZIwFk1bZzyso1JLNMj3evL2Dh9CJHwzrpW-2025-03-15-21_54_38_kccnei.png",
      "https://res.cloudinary.com/dkfuksxev/image/upload/v1742060310/screencapture-localhost-5173-admin-dashboard-2025-03-15-21_53_08_gjstsj.png",
      "https://res.cloudinary.com/dkfuksxev/image/upload/v1742060309/screencapture-localhost-5173-admin-course-2025-03-15-21_53_23_oxgpvf.png"
    ],
    tags: ["React Js", "Node Js", "MongoDb", "Express Js", "JWT Token"],
    category: "web app",
    github: "https://github.com/Vraj-Raval/learning-management-system.git",
  },
  {
    id: 6,
    title: "Bus Booking System",
    date: "Nov 2021 - Dec 2021", // Add date if available
    description:
      "A complete online bus booking system built with ASP.NET. Features include user authentication, seat selection, booking management, and an admin panel for route management.",
    image: "https://res.cloudinary.com/dkfuksxev/image/upload/v1742060257/Online_Bus_Booking_System___Asp.net_C_Project_with_Source_Code___Free_Download_Asp.net_Project_0-1_screenshot_rgpilm.png",
    images: [
      "https://res.cloudinary.com/dkfuksxev/image/upload/v1742060257/Online_Bus_Booking_System___Asp.net_C_Project_with_Source_Code___Free_Download_Asp.net_Project_0-1_screenshot_rgpilm.png",
      "https://res.cloudinary.com/dkfuksxev/image/upload/v1742060228/Online_Bus_Booking_System___Asp.net_C_Project_with_Source_Code___Free_Download_Asp.net_Project_0-9_screenshot_thn6vd.png",
      "https://res.cloudinary.com/dkfuksxev/image/upload/v1742060233/Online_Bus_Booking_System___Asp.net_C_Project_with_Source_Code___Free_Download_Asp.net_Project_0-28_screenshot_vq8azk.png",
      "https://res.cloudinary.com/dkfuksxev/image/upload/v1742060228/Online_Bus_Booking_System___Asp.net_C_Project_with_Source_Code___Free_Download_Asp.net_Project_0-26_screenshot_l23m72.png",
      "https://res.cloudinary.com/dkfuksxev/image/upload/v1742060228/Online_Bus_Booking_System___Asp.net_C_Project_with_Source_Code___Free_Download_Asp.net_Project_0-29_screenshot_u5fhm3.png"
    ],
    tags: ["ASP.NET", "C#", "SQL Server", "MVC", "Entity Framework"],
    category: "web app",
    github: "https://github.com/Vraj-Raval/online-bus-booking.git"
}
,
  {
    id: 1,
    title: "Go Green Way",
    date: "Feb 2024 - Apr 2024",
    description:
      "Developed an innovative e-commerce platform focused on plant enthusiasts,Engineered a seamless buying and selling experience for plants,Enabled users to navigate effortlessly, fostering a personalized and user-friendly online gardening journey.",
    image: "https://res.cloudinary.com/dkfuksxev/image/upload/v1742060888/Home_Page-1_fgupab.png",
    images: [
      "https://res.cloudinary.com/dkfuksxev/image/upload/v1742060943/Home_Page_pgfwe6.png",
      "https://res.cloudinary.com/dkfuksxev/image/upload/v1742060889/screencapture-localhost-3000-Shop-2024-05-29-01_19_58_ubsevp.png",
      "https://res.cloudinary.com/dkfuksxev/image/upload/v1742060889/screencapture-localhost-3000-cart-2024-05-29-01_20_27_ubzh2s.png",
    ],
    tags: ["React Js", "Node Js", "MongoDb", "Express Js", "JWT Token"],
    category: "web app",
    github: "https://github.com/Vraj-Raval/Plant-Ecommerce-Frontend",
    webapp: "https://github.com/Vraj-Raval/Plant-Ecommerce-Backend",
  },
  {
    id: 2,
    title: "E-Nursery",
    date: "Jul 2023 - Aug 2023",
    description:
      "Created a premier e-commerce platform dedicated to footwear fans,Designed and implemented a trendy and user-friendly interface.",
    image:
      "https://res.cloudinary.com/dkfuksxev/image/upload/v1742113276/NWAJDtaovOU-HD_ivdjop.jpg",
    tags: ["Kotlin", "MongoDb"],
    category: "android app",
    github: "https://github.com/Vraj-Raval/Plant-App",
    webapp: "https://github.com/Vraj-Raval/Plant-App",
  },
  {
    id: 3,
    title: "Plant Ecommerce",
    date: "Feb 2023 - Mar 2023",
    description:
      "Introducing the ultimate app for anyone looking to bring a touch of nature into their homes and offices. With our app, you can browse and buy a wide range of house and office plants, including succulents, cacti, ferns, and more!",
    image:
      "https://res.cloudinary.com/dkfuksxev/image/upload/v1742113165/Frame_1_fts2tn.png",
    tags: ["Figma"],
    category: "ui design",
    github: "https://www.figma.com/design/AoQFee1FIaiosbO54CKGTq/Plant-Ecommerc?m=dev&node-id=0%3A1&t=5W6uirQGneSbxa0u-1",
    webapp: "https://www.figma.com/design/AoQFee1FIaiosbO54CKGTq/Plant-Ecommerc?m=dev&node-id=0%3A1&t=5W6uirQGneSbxa0u-1",
  },
  {
    id: 4,
    title: "ToDo List App",
    date: "May 2024",
    description:
      "Manage your tasks efficiently with our easy-to-use to-do list app. Add, edit, and delete tasks with a few taps. Stay organized and boost your productivity!",
    image:
      "https://res.cloudinary.com/dkfuksxev/image/upload/v1742113204/Frame_4_1_rimioo.png",
    tags: ["Flutter"],
    category: "android app",
    github: "https://github.com/Vraj-Raval/Todo-List-Flutter.git",
    webapp: "https://github.com/Vraj-Raval/Todo-List-Flutter.git",
  },
  
];



